import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Preferences as Storage } from '@capacitor/preferences';
import { PrepareService } from '../services/rest/prepare.service';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {
  constructor(private router: Router,
    private api: PrepareService) {
  }

  async canActivate(): Promise<boolean> {
    const val = await Storage.get({ key: 'user' }); 
    if (!val.value) {
      return true;
    } else {
      this.router.navigate(['/tabs/compte']);
      return false;
    }
  }
}
